<template>
  <div>
    <iframe :src="privacyUrl"
            width="100%"
            style="height: 95vh"
            frameborder="0"
            allowfullscreen>
      <p>Your browser does not support iframes.</p>
    </iframe>
  </div>
</template>

<script>
  export default {
    name : 'PrivacyPolicy',
    head : {
      title : {
        inner      : 'Privacy Policy',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        privacyUrl : "https://app.termly.io/document/privacy-policy/cbfc5846-" +
          "a68d-4d07-82bb-ee0df4551cc9",
      }
    },
  };
</script>
